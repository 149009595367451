jQuery.noConflict();

(function ($){

	$(document).ready(function(){

		$(document).foundation();




		$(".add-new-line").on("click touchstart" , function(){
			$(".new-product-box").append("<input type='integer' name='product_ids[]' />");
		});

		$(".current-store").on("hover click" , function(){
			$('.store-list').toggleClass('animate');
			$('.pre-header--right').toggleClass('active');
			$(".current-store.for-large").toggleClass('active');
		});

		// NAVIGATION HOVER AND SORT
		$("#nav li").each(function(){
			$(this).has('ul.level0').addClass('has-sub');
			$(this).has('ul.level0').append('<span class="sub-opener"><i class="fa fa-sort-down"></i></span>')
		});

		$(".product-questions--box p").on("click touchstart" , function(e){
			e.preventDefault();
			$(".product-questions--box .phone").toggleClass('open');
		});


        // hover navigation
        $(".top-bar li.has-sub").hover(function(){
            $(this).addClass('active');
			$(this).addClass('hovered');
            $(this).find('ul.level0').addClass('sub-active');
        }, function(){
            $(this).removeClass('active');
			$(this).removeClass('hovered');
            $(this).find('ul.level0').removeClass('sub-active');
        });

        // click navigation
		var navOpen = false;

		$(".top-bar li.has-sub .sub-opener").on("click touchstart" , function(e){

            e.preventDefault();
            if($(".top-bar li.has-sub").hasClass('active')){
                navOpen = false;
            }
            if($(this).parent().hasClass('active')){
                navOpen = true;
            }
            $(".top-bar li.has-sub").removeClass('active');
            $(".top-bar li.has-sub ul.level0.sub-active").removeClass('sub-active');
            if(navOpen){
                $(this).parent().removeClass('active');
                $(this).parent().find('ul.level0').removeClass('sub-active');
                navOpen = false;
            }else if(!navOpen){
                $(this).parent().addClass('active');
                $(this).parent().find('ul.level0').addClass('sub-active');
                navOpen = true;
            }

		});

		// hamburger / top-search
		$('.action-list .search').bind('click touchstart' , function(){
			$(".header-search.for-small").toggleClass('animate');
		});

		$(".hamburger-menu").bind("click touchstart" , function(e){
			e.preventDefault();
			$('.top-bar li').removeClass('active');
			$('.top-bar li.has-sub ul.level0').removeClass('sub-active');
			navOpen = false;
			$(".top-bar").toggleClass('animated');
			$(".nav-overlay").toggleClass('active');
		});

		// BX-Slider home

		$.extend($.lazyLoadXT, {autoInit:false});

		// load first image to give height and width for bxslider
		var preloadableImages = $();
		preloadableImages = preloadableImages.add($('.main-stage .slide:lt(1) img'));

		preloadableImages = preloadableImages.add($('.load-lazy'));

		preloadableImages.lazyLoadXT({show: true});

		preloadableImages.one('lazyload' , function(){

			$(".main-stage").bxSlider({
				slideWidth: $(".main-slider--outer").width() + "px",
				minSlides: 1,
				maxSlides: 1,
				slideMargin: 0,
				prevText : '<i class="fa fa-angle-left"></i>',
				nextText : '<i class="fa fa-angle-right"></i>',
				onSlideBefore: function ($elem, oldIndex, newIndex) {
					var nextImages = $elem.find("img");
					nextImages.lazyLoadXT({show: true, checkDuplicates: false});
				}
			});
		});

		$(document).on("lazycomplete" , function(){

			if(Foundation.MediaQuery.atLeast('medium')){
				var slideWidth = 285;
			}else{
				var slideWidth = 173;
			}

			var slide = $(".product_slider").bxSlider({
				slideWidth: slideWidth,
				minSlides: 1,
				maxSlides: 4,
				responsive: true,
				slideMargin: 15,
				infiniteLoop: true,
				pager: true,
				controls: true,
				prevText : '<i class="fa fa-angle-left"></i>',
				nextText : '<i class="fa fa-angle-right"></i>',
			});

			if(Foundation.MediaQuery.atLeast('medium')){
				var slideWidth = 287;
				var slideMargin = 15;
			}else{
				var slideWidth = 173;
				var slideMargin = 10;
			}

			var largeSlide = $(".product_slider--large").bxSlider({
				slideWidth: slideWidth,
				minSlides: 1,
				maxSlides: 4,
				responsive: true,
				slideMargin: slideMargin,
				pager: true,
				controls: true,
				prevText : '<i class="fa fa-angle-left"></i>',
				nextText : '<i class="fa fa-angle-right"></i>'

			});

			$(".upsell-products").bxSlider({
				slideWidth: 300,
				minSlides: 1,
				maxSlides: 4,
				responsive: true,
				slideMargin: 15,
				pager: true,
				controls: true,
				prevText : '<i class="fa fa-angle-left"></i>',
				nextText : '<i class="fa fa-angle-right"></i>'
			});








		});

		$(".technical-details").on("click" , function(){
			$(this).parent().parent().find('.technical-info').toggleClass('visible');
		});

		$(".technical-info .fa-close").on("click" , function(){
			$(this).parent().toggleClass('visible');
		});


		// make navigation sticky

		if($("body .page").height() > 1300){
			var stickyAnchor = jQuery('.top-bar').offset().top;

			$(window).on({
				scroll : function(){
					var currentPosition = window.pageYOffset;
					if( currentPosition > stickyAnchor ) {
						$('.top-bar').addClass('animate');
						$('.header-logo').addClass('animate');
					} else {
						$('.top-bar').removeClass('animate');
						$('.header-logo').addClass('animate');
					}
				}
			});

			$(".top-bar-sticky--go-top").on("click" , function(){
				$('html, body').animate({scrollTop : 0},800);
				return false;
			});

		}



		$(".more-views").bxSlider({
			slideWidth: 120,
			slideMargin: 10,
			minSlides: 3,
			maxSlides: 4,
			pager: false,
			prevText : '<i class="fa fa-angle-left"></i>',
			nextText : '<i class="fa fa-angle-right"></i>'

		});

		// media query event handler
		if (matchMedia) {
			var mq = window.matchMedia("(min-width: 690px)");
			mq.addListener(WidthChange);
			WidthChange(mq);
		}

		// media query change
		function WidthChange(mq) {

			if (mq.matches) {
				// window width is at least 690px
				$('.gallery-image.visible').elevateZoom({
					zoomType: 'window',
					cursor: "crosshair",
					easing : true,
					scrollZoom : true,
				});

				$('.more-views').click(function(){
					$('.gallery-image.visible').elevateZoom({
						zoomType: 'window',
						cursor: "crosshair",
						easing : true,
						scrollZoom : true
					});
				})
			}

		}

		$(".filter-link-opener").on("click" , function(){
			$('.block.block-layered-nav .filter-content').toggleClass('hide');
		});

		$(".more-views .item").first().addClass('active');

		$(".more-views .item").on('click' , function(){
			$(".more-views .item").removeClass('active');
			$(this).addClass('active');
		})

		lightbox.option({
			albumLabel : 'Produkt %1 von %2'
		});

	});
}(jQuery));
